import React from 'react';
import styled from '../styled';

const Wrapper = styled('a')`
  display: block;
  position: relative;

  &:hover .play-button {
    top: 48%;
  }
`;

const PlayButton = styled('img')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 0.2s ease-in-out;
`;

const Img = styled('img')`
  max-width: 100%;
  border: 1px solid #0d0f11;
  display: inline-block;
  border-radius: 8px;
`;

export const CommunityVideoPreview = ({
  url,
  src,
  alt
}: {
  url: string;
  src: string;
  alt: string;
}) => {
  return (
    <Wrapper href={url} target="_blank">
      <PlayButton
        src="/images/play-button.png"
        className="play-button"
        title={`Click to play ${alt}`}
      />
      <Img src={src} alt={alt} title={`Click to play ${alt}`} />
    </Wrapper>
  );
};
