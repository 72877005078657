import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from 'gatsby';
import { TwitterScript } from '../../components/TwitterScript';
import { CommunityVideoPreview } from '../../components/CommunityViewPreview';
export const _frontmatter = {
  "commentId": "eve-porcello-interview",
  "image": "/images/interviews/eve-ama-preview.png",
  "date": "2021-03-19",
  "socialSharingImage": "/images/interviews/eve-ama-preview.png",
  "title": "Break Into Tech Publishing and Training with Eve Porcello",
  "summary": "Eve Porcello is an O'Reilly author, Egghead Instructor, and co-owns the developer training and curriculum development company called Moon Highway. Learn how she got into enterprise training, running workshops, and teaching online.",
  "seoTitle": "Break Into Tech Publishing and Training with Eve Porcello",
  "seoDescription": "Eve Porcello is an O'Reilly author, Egghead Instructor, and co-owns the developer training and curriculum development company called Moon Highway. Learn how she got into enterprise training, running workshops, and teaching online.",
  "tweet": "The fascinating story behind Eve Porcello's career as a technical author and trainer",
  "author": "monica",
  "interviewImage": "/images/interviews/eve-porcello.jpg",
  "interviewName": "Eve Porcello",
  "interviewJob": "Co-Author of Learning GraphQL, Egghead Instructor, and co-founder of Moon Highway",
  "tags": ["interview", "blogging"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <img src="/images/interviews/eve-ama-preview.png" alt="Eve Porcello" style={{
      "maxWidth": "100%",
      "border": "1px solid #0D0F11",
      "display": "inline-block",
      "margin": "24px auto",
      "borderRadius": "8px"
    }} />
    <p>{`In this interview, Eve shares:`}</p>
    <ul>
      <li parentName="ul">{`How she landed a book deal with O'Reilly`}</li>
      <li parentName="ul">{`How she broke into Enterprise training`}</li>
      <li parentName="ul">{`How COVID affected her training business in 2020`}</li>
      <li parentName="ul">{`The breakdown in her income between various courses, trainings, and workshops`}</li>
      <li parentName="ul">{`Where her clients come from and how she attracts them through content`}</li>
      <li parentName="ul">{`The balance between beginner, intermediate, and advanced content`}</li>
      <li parentName="ul">{`When you should self-publish or launch a course or book independently`}</li>
    </ul>
    <p>{`Let's roll ✌️`}</p>
    <p><em parentName="p">{`This interview is an excerpt from the `}<Link to="/pro/" mdxType="Link">{`Blogging for Devs Community`}</Link>{` AMA session with Eve.`}</em></p>
    <h2>{`Welcome Eve! How did you land a book deal with O'Reilly? What was the experience like?`}</h2>
    <p>{`Thank you so much for having me. This is so fun.`}</p>
    <p>{`Well, I think O'Reilly was very weird, because we had created some `}<a href="https://www.linkedin.com/learning/learning-react-js-5" target="_blank">{`courses with LinkedIn Learning`}</a>{` on React and Node.js, and things like that.`}</p>
    <img src="/images/interviews/eve-linkedin-courses.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`I say we because I work with my husband, Alex Banks, quite closely on this stuff. And we worked on the book together.`}</p>
    <p>{`So yeah, `}<a href="https://www.oreilly.com/library/view/learning-react-2nd/9781492051718/" target="_blank">{`Learning React`}</a>{` came out in 2017. Before that, in 2015, we sent an email through, like, a Contact Us form. And we had never written a blog, let alone a book.`}</p>
    <p>{`So that was a very out of order, and not possibly a good idea to do that! But we ended up, because of our work with LinkedIn Learning, seeming sort of credible at being able to write something.`}</p>
    <p>{`I think it was sort of early on in React, not a lot of people were pitching that as a topic. But it was something that a lot of people were starting to use. So right place at the right time for that, for sure.`}</p>
    <img src="/images/interviews/eve-learning-react-book.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`I think O'Reilly in general is a nice partner because it gives you a bit of credibility if you aren't going down the road of self-publishing. But self-publishing can be a good route because you get all the money. You do not get all the money with O'Reilly.`}</p>
    <p>{`In fact, you get very little of the money.`}</p>
    <p>{`So that's something to keep in mind, if you do go with a publisher, it leads to potentially more credibility. But it also, your cut is a lot smaller.`}</p>
    <h2>{`That's great to know, some people have been asking whether you'd consider self-publishing in the future?`}</h2>
    <p>{`I think at the time, we benefited from the structure of their process a lot,
which was like, do things in this order. Put together a book that goes through
an editing process and things like that. So we were able to rely on editors.`}</p>
    <p>{`Now I feel like, a lot of the tools for self publishing as developers are in our hands.`}</p>
    <p>{`Now you can create a book with markdown you can hire an editor or I don't know, edit it yourself. I don't think I could personally do that, because it would not be a good outcome.`}</p>
    <p>{`But the O'Reilly kind of banner has been...I'd be lying if I said, it didn't help us in certain rooms, where all they care about is that like seeing an animal and your name on the cover of a book.`}</p>
    <p>{`But yeah, if that's something that's a goal, or you're interested in doing, there's a lot of interesting channels for how to get in there.`}</p>
    <img src="/images/interviews/eve-graphql-book.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <h2>{`So how'd you get into enterprise training?`}</h2>
    <p>{`Yeah, so we started `}<a href="https://moonhighway.com/" target="_blank">{`our company`}</a>{` in 2012, as a consulting
company for development. So we worked on a lot of
agency projects and things like that.`}</p>
    <p>{`And then we wanted to get into teaching.`}</p>
    <p>{`I sent a lot of cold emails to companies being like, this is what we can do for
you, we can teach all of these awesome classes, etc.`}</p>
    <p><strong parentName="p">{`And I got no responses whatsoever.`}</strong></p>
    <p>{`So like, total dead end there with teaching as a service
that we were offering. What we did was we started teaching through other
companies. So there was one called `}<a href="https://www.developintelligence.com/" target="_blank">{`Develop Intelligence`}</a>{`. There's one called `}<a href="https://www.sbtrain.com/" target="_blank">{`Silicon Bay Training`}</a>{`, and all these other companies who had better salespeople
than me who are going in selling JavaScript classes.`}</p>
    <img src="/images/interviews/eve-develop-intelligence.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`A lot of big companies have training departments. And so they offer a series of classes each month. And so a lot of those we taught online, a lot of those we taught in person. So yeah, throughout that process, we were just teaching through these other companies and getting staffed on that.`}</p>
    <p>{`At that point, I was teaching all sorts of classes.`}</p>
    <p><strong parentName="p">{`Google Docs was a class I taught, not to brag.`}</strong></p>
    <p>{`So that really helped us a lot to a learn to teach better, and also to create curriculum more successfully. And just to get our foot in the door to teach because obviously, my sales efforts were unsuccessful in that realm.`}</p>
    <p>{`So as time continued, we worked on the LinkedIn Learning courses, we worked on the books. And as we started to create our own content.`}</p>
    <img src="/images/interviews/eve-moon-highway.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`Eventually, people sort of had a little more confidence that we could just come and teach their people, we started to be able to book those opportunities directly.`}</p>
    <p>{`But that took a really long time.`}</p>
    <p>{`People are always like, yeah, it's really cool that you do that.`}</p>
    <p><strong parentName="p">{`But it was like five years of complete failure to getting...no six, seven years, really, of not being able to book training classes directly, because no one knew who we were.`}</strong></p>
    <img src="/images/interviews/eve-moon-highway-about.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <h2>{`Does that mean today that most of your training opportunies come inbound? Do they find you on your blog?`}</h2>
    <p>{`So I always thought that just by putting up our website and sending a few cold emails that I would, that that things would work out really well.`}</p>
    <p>{`And that wasn't the case, it has been after creating a bunch of stuff that people trust.`}</p>
    <p>{`Yeah, it mostly is inbound these days. But that has not definitely not always been the case.`}</p>
    <p>{`I think blogging has been extremely helpful, just because first off, it allows us to create content for our courses. And it gives us this way of sharing something for free with people.`}</p>
    <img src="/images/interviews/eve-blog.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`And just getting out there in a way that is a little bit more impactful than like, assuming everyone buys our book, which is not going to happen.`}</p>
    <p>{`So people will be like, "I read this blog, want to come teach a class?"`}</p>
    <p>{`And we're like, "Yes, we do."`}</p>
    <h2>{`And how is your income divided between all these activities? Books, LinkedIn Learning, training, and now you're doing Egghead as well?`}</h2>
    <p>{`For us, personally, about 50% of our income comes from LinkedIn Learning, we've created about 12 courses through them. And about 2% comes from egghead about 10% comes from O'Reilly. And then the rest comes from corporate training.`}</p>
    <p>{`So first things first, with LinkedIn Learning, we formerly got 10% of the revenue of those courses. That has now gone up to 20%, because we started editing our own videos, which has been a good thing.`}</p>
    <p>{`LinkedIn has been really good for us to kind of keep our business open as we grow as instructors. They also give you a $3,000 advance, which goes along with how much they expect for you to make from the course.`}</p>
    <img src="/images/interviews/eve-linkedin-learning-course.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`Our React courses there have made anywhere from like $50,000 to $150,000. Which is a lot. Kind of way more than we were expecting from those platforms.`}</p>
    <p>{`What I used to make for teaching through other companies was like $1,000 a day. That was a pretty standard rate for courses. Now we charge more, just because we handle all the sales and we develop the content and things like that. So that's more like $5,000 to $10,000 a day, depending on the depending on the company.`}</p>
    <p><strong parentName="p">{`Something I like to do before I charge for something is to look up how much the company makes, and it's usually a really large number, much larger than the number that I'm asking for.`}</strong></p>
    <p>{`And so that helps me that helps me keeps keep the lights on. So I'm very, very good at undercharging. That's something I'm working on.`}</p>
    <h2>{`Thanks for sharing this, it's really helpful to see how it all fits together financially! How are you balancing beginner, intermediate, and advanced content?`}</h2>
    <p>{`I think it's really hard. I think I get dinged personally for not writing enough advanced or in-depth things. And that's just because...I don't know why that is. That's like a therapy session that I could talk about at a different time.`}</p>
    <p>{`But I should probably focus more on that stuff. Because sometimes people will be like, "Yeah, I don't need your services, because I can't I just read the Docs or whatever?"`}</p>
    <p>{`But yeah, I think beginner things usually lead to leads for us just because, people are looking for one tricky thing about GraphQL, in particular, when they're getting started.`}</p>
    <p>{`And so kind of the way that we've approached that is like we do this `}<a href="https://moonhighway.com/graphql/everyone" target="_blank">{`GraphQL is for Everyone`}</a>{` class that's aimed at managers, and designers and developers, and things like that.`}</p>
    <img src="/images/interviews/eve-graphql-is-for-everyone.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`And that sort of leads into getting people converted into wanting to take the next step. It's free, one hour, once a month. And it's very repeatable. But you are live, and if anybody has different questions, you can answer them.`}</p>
    <p>{`That's been kind of a good way to get people on our mailing list and if people are like, this isn't for me, I don't know if I can really do this stuff, that has led to conversions for other stuff.`}</p>
    <h2>{`With such a focus on training, how did COVID affect your business?`}</h2>
    <p>{`That did happen with LinkedIn Learning at the beginning of the pandemic.`}</p>
    <p>{`I got an email from our content manager over there, and he was like, "Hey, your income statement is gonna come. And it's gonna be a lot less because people have been watching a lot of videos about creating resumes and getting new jobs and all of those, like Career Builder type classes." Because there was a lot of, obviously anxiety at the beginning of that, and a lot of people looking for new work.`}</p>
    <p>{`So that has really stabilized a lot. But the algorithm can do unexpected things when deadly viruses start circling, and you're in a revenue-share situation.`}</p>
    <h2>{`Is that why you've got several different income streams set up? Because you like the diversification?`}</h2>
    <p>{`I think we'd like having the mix, there were a couple years where we're about 90% of our income was from LinkedIn Learning. And that is good.`}</p>
    <p>{`But what happens if that website goes away? Or they, I don't know, decide they don't like you, or they start to hire all all like, in house trainers to teach off the workshops and just delete your courses, things like that.`}</p>
    <p>{`Before we did any content development, that was a scary place to be, because we would get these opportunities to teach for two weeks, we would make all this money.`}</p>
    <img src="/images/interviews/eve-graphql-workshop.png" style={{
      "maxWidth": "100%",
      "borderRadius": "8px"
    }} />
    <p>{`And we'd be like, sweet, this is awesome, this is gonna work.`}</p>
    <p>{`And then there would be months where we just didn't hear anything. And that's could be interpersonal stuff, that could be sales. Could be that could be a whole host of different reasons why we weren't getting as many calls. And we couldn't depend on that income. So when we were able to split it up, we were able to have a little bit more control.`}</p>
    <p>{`I do feel like over time that has the diversity of income streams has made life a little bit less stressful for us.`}</p>
    <h2>{`Big thanks to Eve for joining this session!`}</h2>
    <p>{`If you're a member of the `}<Link to="/pro/" mdxType="Link">{`Blogging for Devs Pro Community`}</Link>{`, you've got access to the full, one-hour video session! Click the video to check it out:`}</p>
    <CommunityVideoPreview url="https://community.bloggingfordevs.com/c/video-library/eve-porcello-ama" src="/images/interviews/eve-ama-preview.png" alt="AMA Video with Eve Porcello" mdxType="CommunityVideoPreview" />
    <br />
    <p>{`If you'd like to learn more about Eve, you can find her on Twitter `}<a href="https://twitter.com/eveporcello" target="_blank">{`@eveporcello`}</a>{`, on her training company's website `}<a href="https://moonhighway.com/" target="_blank">{`Moon Highway`}</a>{`,
or check out her upcoming `}<a href="https://graphqlworkshop.com/" target="_blank">{`GraphQL Workshop`}</a>{`!`}</p>
    <TwitterScript mdxType="TwitterScript" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      